<template>
  <div class="son-page">
    <div class="top-change">
      <div class="top-search">
        <Form inline :label-width="100">
            <FormItem style="margin:0" label="商品分类名称">
                <Input placeholder="请输入商品分类名称" v-model="storePrams.keyword" style="width: 180px;margin-right: 20px"/>
            </FormItem>
            <FormItem style="margin:0" label="状态">
                <Select v-model="storePrams.status" style="width:180px">
                  <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </FormItem>
            <FormItem style="margin:0">
                <div @click="searchBtn" class="btn-style">搜索</div>
            </FormItem>
        </Form>
      </div>
    </div>
    <div class="table-wrap">
      <div class="add_search_box" style="padding:20px 0">
        <div>
          <Select v-model="modl" style="width:180px">
            <Option v-for="item in cityList1" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
          <Button style="margin-left:20px" @click="implement" class="btn">执行</Button>
        </div>
        <div><Button @click="addClass('add')" class="btn">添加分类</Button></div>
      </div>
      <Table row-key="id" @on-selection-change="selectionChange" ref="selection" stripe :columns="worlkColumns" :data="data6">
        <template slot-scope="{ row }" slot="status">
          <i-switch @on-change="put(row.id)" v-model="row.status" size="large" :true-value="1" :false-value="2">
            <span slot="open">上架</span>
            <span slot="close">下架</span>
          </i-switch>
        </template>
        <template slot-scope="{ row }" slot="operation">
          <Button v-if="row.pid==0" @click="addClass(row.id)" type="info" ghost size="small" style="margin-right: 5px;color:#10B4D1;border-color:#10B4D1">新增二级分类</Button>
          <Button @click="addClass(row)" type="info" ghost size="small" style="margin-right: 5px">编辑</Button>
          <Button @click="delBtn(row.id)" type="error" ghost size="small">删除</Button>
        </template>
      </Table>
      <br/>
      <Page :total="total"
            :current="storePrams.page"
            style="text-align:right"
            show-elevator
            show-total
            @on-change="pageChange"
            :page-size="storePrams.limit"/>
    </div>
    <Modal
        v-model="classModal"
        :mask-closable="false"
        :title="classTitle"
        @on-cancel="cancel">
        <Form ref="formData" :rules="ruleValidate" :model="formData" label-position="left" :label-width="100">
          <FormItem label="排序" prop="sort">
              <Input v-model="formData.sort"></Input>
          </FormItem>
          <FormItem label="分类名称" prop="cate_name">
              <Input v-model="formData.cate_name"></Input>
          </FormItem>
      </Form>
        <div slot="footer">
            <Button @click="cancel">取消</Button>
            <Button @click="classBtn('formData')" class="btn">保存</Button>
        </div>
    </Modal>
  </div>
</template>

<script>
import {cateList,addCate,updateCate,handle,cateDel,cateHandle,addSecondLevel} from '@/api/customer'
export default {
  name: "canteen",
  data(){
    return{
      modl:'0',
      classModal:false,
      ids:[],
      cityList: [
        {
          value: '0',
          label: '所有'
        },
        {
          value: '1',
          label: '上架'
        },
        {
          value: '2',
          label: '下架'
        }
      ],
      cityList1: [
        {
          value: '1',
          label: '全部下架'
        },
        {
          value: '2',
          label: '全部上架'
        },
        {
          value: '3',
          label: '全部删除'
        }
      ],
      formData:{
        sort:'',
        cate_name:''
      },
      ruleValidate: {
          sort: [
              { required: true, message: '请输入排序', trigger: 'blur' }
          ],
          cate_name: [
              { required: true, message: '请输入分类名称', trigger: 'blur' }
          ],
      },
      classTitle:'添加分类',
      data6:[],
      worlkColumns:[
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '#',
          key: 'id',
          align: 'center',
          tree: true
        },
        {
          title: '排序',
          key: 'sort',
          align: 'center'
        },
        {
          title: '分类名称',
          key: 'cate_name',
          align: 'center'
        },
        {
          title: '上下架',
          slot: 'status',
          align: 'center'
        },
        {
          title: '操作',
          key: 'action',
          width: 260,
          fixed: 'right',
          align: 'center',
          slot: 'operation'
        }
      ],
      total:0,
      storePrams:{
        status:'0',
        keyword:'',
        page:1,
        limit:10
      },
    }
  },
  created() {
    this.getList()
  },
  methods:{
    getList(){
      cateList(this.storePrams).then(res=>{
        this.total = res.data.total
        this.data6 = res.data.data
        // console.log(this.data6[0]._disabled = true);
      })
    },
    put(id){
          handle({id:id}).then(res=>{
            // if (e.status==1) {
            //   e.status=2
            // }else{
            //   e.status=1
            // }
            this.getList()
            this.$Message.success(res.msg)
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
    },
    searchBtn(){
      this.storePrams.page = 1
      this.getList()
    },
    delBtn(id){
      this.$Modal.confirm({
        title: '提示',
        content: `您正在使用删除功能`,
        onOk: () => {
          cateDel({id:id}).then(res=>{
            this.$Message.success(res.msg)
            this.getList()
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    pageChange(index){
      this.storePrams.page = index
      this.getList()
    },
    cancel(){
      this.formData = {
        num : '',
        name : ''
      }
      this.classModal = false
    },
    implement(){
      let data = {
        id:this.ids,
        type:this.modl,
      }
      if (data.id.length!=0&&data.type!='0') {
        cateHandle(data).then(res=>{
          this.$Message.success(res.msg)
          this.ids = []
          this.modl = '0'
          this.getList()
        }).catch(err=>{
          this.$Message.error(err.msg)
        })
      }else{
        this.$Message.warning('请选择参数操作')
      }
    },
    selectionChange(row){
        this.ids = []
        row.forEach(item=>{
            this.ids.push(item.id)
        })
    },
    addClass(e){
      if (e==='add') {
        this.classTitle = '添加分类'
        this.classModal = true
      }else if(e.cate_name){
        this.classTitle = '编辑分类'
        this.classModal = true
        this.formData.id = e.id
        this.formData.sort = e.sort.toString()
        this.formData.cate_name = e.cate_name
      }else{
        this.classTitle = '新增二级分类'
        this.classModal = true
        this.formData.id = e
      }
    },
    classBtn(name){
      this.$refs[name].validate((valid) => {
          if (valid) {
            if (this.classTitle === '添加分类') {
              addCate(this.formData).then(res=>{
                this.$Message.success(res.msg)
                this.formData = {num : '',name : ''}
                this.classModal = false
                this.getList()
              }).catch(err=>{
                this.$Message.error(err.msg)
              })
            }else if(this.classTitle === '新增二级分类'){
              addSecondLevel(this.formData).then(res=>{
                this.$Message.success(res.msg)
                this.formData = {num : '',name : ''}
                this.classModal = false
                this.getList()
              }).catch(err=>{
                this.$Message.error(err.msg)
              })
            }else{
              updateCate(this.formData).then(res=>{
                this.$Message.success(res.msg)
                this.formData = {num : '',name : ''}
                this.classModal = false
                this.getList()
              }).catch(err=>{
                this.$Message.error(err.msg)
              })
            }
          } else {
              // this.$Message.error('Fail!');
          }
      })
    },
  }
}
</script>

<style scoped>
.son-page {

}
.top-change {
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 20px 30px;
  box-sizing: border-box;
}
.top-search {
  width: 100%;
  background: white;
  padding: 20px 0;
  border-radius: 10px;
  display: flex;
}
.two-btns {
  display: flex;
  align-items: center;
  margin-left: 40px;
}
.btn-style {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  background: #FF761D;
  width: 80px;
  height: 32px;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.order-item {
  margin-top: 20px;
  background: white;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}
.phone {
  font-size: 14px;
  font-weight: bold;
}
.top-num {
  font-size: 13px;
  color: #585858;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.top-num .name1 {
  margin-right: 40px;
}
.bottom-content {
  font-size: 15px;
  margin-top: 20px;
  word-break: break-all;
}
.r-bent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}
.right1 {
  text-align: right;
}
.l-went {
  flex: 1;
}
.right-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.right-btn .btn1 {
  width: 60px;
  height: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ff5c11;
  color: #ff5c11;
  margin-left: 20px;
  border-radius: 4px;
  cursor: pointer;
}
.add_search_box{
  display: flex;
  justify-content: space-between;
}
.right-btn .btn2 {
  color: #343434;
  border: 1px solid #555555;
}
.right-btn .btn2:hover,.right-btn .btn1:hover{
  opacity: 0.6;
}
.status-pic {
  width: 117px;
  height: 88px;
  position: absolute;
  right: 210px;
  top: 50%;
  transform: translateY(-50%);
}
.status-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.table-wrap {
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 20px 30px;
  box-sizing: border-box;
  margin-top: 30px;
}
.top-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
<style>
.ivu-checkbox-disabled{
  display: none !important;
}
.ivu-tabs-nav .ivu-tabs-tab {
  display: inline-block;
  height: 100%;
  padding: 8px 16px;
  margin-right: 16px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  transition: color .3s ease-in-out;
}
.ivu-tabs-nav .ivu-tabs-tab-active {
  color: #FF761D;
}
.ivu-tabs-nav .ivu-tabs-tab:hover {
  color: #FF761D;
}
.ivu-tabs-ink-bar {
  height: 2px;
  box-sizing: border-box;
  background-color: #FF761D;
  position: absolute;
  left: 0;
  bottom: 1px;
  z-index: 1;
  transition: transform .3s ease-in-out;
  transform-origin: 0 0;
}
</style>
