import request from '@/utils/request'

export function cateList (data) {
    return request({
        url: '/goods/cateList',
        method: 'post',
        data
    })
}

export function addCate (data) {
    return request({
        url: '/goods/addCate',
        method: 'post',
        data
    })
}

export function updateCate (data) {
    return request({
        url: '/goods/updateCate',
        method: 'post',
        data
    })
}

export function handle (data) {
    return request({
        url: '/goods/handle',
        method: 'post',
        data
    })
}

export function cateDel (data) {
    return request({
        url: '/goods/cateDel',
        method: 'post',
        data
    })
}

export function cateHandle (data) {
    return request({
        url: '/goods/cateHandle',
        method: 'post',
        data
    })
}

export function goodsList (data) {
    return request({
        url: '/goods/goodsList',
        method: 'post',
        data
    })
}

export function getCate (data) {
    return request({
        url: '/goods/getCate',
        method: 'post',
        data
    })
}

export function addGoods (data) {
    return request({
        url: '/goods/addGoods',
        method: 'post',
        data
    })
}

export function getMode (data) {
    return request({
        url: '/data/getMode',
        method: 'post',
        data
    })
}

export function goodsDetail (data) {
    return request({
        url: '/goods/goodsDetail',
        method: 'post',
        data
    })
}

export function updateGoods (data) {
    return request({
        url: '/goods/updateGoods',
        method: 'post',
        data
    })
}

export function goodsDel (data) {
    return request({
        url: '/goods/goodsDel',
        method: 'post',
        data
    })
}

export function enable (data) {
    return request({
        url: '/goods/enable',
        method: 'post',
        data
    })
}

export function goodsHandle (data) {
    return request({
        url: '/goods/goodsHandle',
        method: 'post',
        data
    })
}

export function updateMode (data) {
    return request({
        url: '/goods/updateMode',
        method: 'post',
        data
    })
}

export function updateStock (data) {
    return request({
        url: '/goods/updateStock',
        method: 'post',
        data
    })
}

export function goodsRecommend (data) {
    return request({
        url: '/goods/goodsRecommend',
        method: 'post',
        data
    })
}

export function setBusinessHours (data) {
    return request({
        url: '/goods/setBusinessHours',
        method: 'post',
        data
    })
}

export function getGoods (data) {
    return request({
        url: '/goods/getGoods',
        method: 'post',
        data
    })
}

export function setGoodsVipConf (data) {
    return request({
        url: '/goods/setGoodsVipConf',
        method: 'post',
        data
    })
}

export function goodsLimit (data) {
    return request({
        url: '/goods/goodsLimit',
        method: 'post',
        data
    })
}

export function goodsQuery (data) {
    return request({
        url: '/goods/query',
        method: 'post',
        data
    })
}

export function addSecondLevel (data) {
    return request({
        url: '/goods/addSecondLevel',
        method: 'post',
        data
    })
}

export function getSecondLevel (data) {
    return request({
        url: '/goods/getSecondLevel',
        method: 'post',
        data
    })
}